<template>
  <!-- 用户管理页面 -->
  <div>
    <!-- 头部开始 -->
    <el-row class="pur-top">
      <el-col :span="3">
        <span class="pur-size">{{ purTitle }}</span></el-col>
        <el-col :span="21" class="pur-right">
            <el-button size="small" @click="openPur">删除</el-button>
            <el-button size="small" style="margin-left: 24px" @click="addPurs" type="primary">新增</el-button>
            <el-input
            @keyup.enter.native="search"
            size="small"
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            v-model="purSearch">
            </el-input>
        </el-col>
    </el-row>
    <!-- 头部结束 -->
    <!-- 内容开始 -->
    <div class="pur-nav"
    v-loading="loading"
    element-loading-text = "加载中...">
      <!-- 表格 -->
      <div class="pur-table">
        <el-table
          height="100%"
          border
          :data="tableData"
          :header-cell-style="{ background: '#F6F6F6', color: '#333' }"
          style="width: 100%;"
          @selection-change="purChange">
            <el-table-column type="selection"></el-table-column>
            <el-table-column prop="id" v-if="show">
            </el-table-column>
            <el-table-column label="用户名称" :show-overflow-tooltip="true" prop="username">
            </el-table-column>
            <el-table-column label="账号" :show-overflow-tooltip="true" prop="account">
            </el-table-column>
            <el-table-column label="手机号" :show-overflow-tooltip="true" prop="alarmPhone">
            </el-table-column>
            <el-table-column label="短信报警" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                  <span v-if="scope.row.msgAlarmFlag">开启</span>
                  <span v-else>关闭</span>
              </template>
            </el-table-column>
            <el-table-column label="授权设备列表" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span style="color:rgba(58, 111, 195, 1);cursor: pointer;" @click="details(scope.row)">详情</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作" width="180" fixed="right">
              <template slot-scope="scope">
                <el-button @click="EditPurs(scope.row)" size="mini">编辑</el-button>
                <el-button @click="Resetpassward(scope.row.id)" size="mini">重置密码</el-button>
              </template>
            </el-table-column>
          <!-- 暂无数据 -->
          <div slot="empty" class="dataAvailable">
              <div class="dataAva">
                <img src="../assets/zanwushuju.png" alt="">
                <p>暂无数据，您可以新建试试</p>
                <el-button size="small" style="margin-left: 24px" @click="addPurs">新增</el-button>
              </div>
          </div>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pur-pages">
        <el-pagination
        background
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size.sync="size"
        :pager-count="5"
        :current-page.sync="current"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @current-change='getpurcon'
        @size-change='getpurcon'>
        </el-pagination>
      </div>
      <!-- 新建用户信息弹窗 -->
      <el-dialog
        :closeOnClickModal="false"
        title="新增用户管理"
        :visible.sync="paymentShow"
        width="35%"
        :before-close="paymentShowClose">
          <el-form style="width:95%;" :model="AddData" :rules="paymentrules" ref="AddData" size="small" label-width="100px">
            <el-form-item label="用户名称">
              <el-input v-model="AddData.username"></el-input>
            </el-form-item>
            <el-form-item label="用户账号" prop="account">
              <el-input v-model="AddData.account"></el-input>
            </el-form-item>
            <el-form-item label="账号密码" prop="password">
              <el-input v-model="AddData.password"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="alarmPhone">
              <el-tooltip effect="dark" content="多个手机号以英文字母逗号隔开" placement="top">
                <el-input v-model="AddData.alarmPhone"></el-input>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="短信报警">
              <el-switch v-model="AddData.msgAlarmFlag"></el-switch>
            </el-form-item>
            <el-form-item label="授权设备列表">
              <el-select v-model="AddData.ids" multiple filterable placeholder="请选择用户账号" style="width:100%;">
                  <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.terminalId"
                  :value="item.id">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="paymentsubmitm('AddData')" style="float:right;margin:5% 0 0 2%;">确定</el-button>
              <el-button @click="paymentresetForm" style="float:right;margin:5% 0 0 2%;">取消</el-button>
            </el-form-item>
          </el-form>
      </el-dialog> 
      <!-- 编辑用户信息弹窗 -->
      <el-dialog
        :closeOnClickModal="false"
        title="编辑用户管理"
        :visible.sync="editpaymentShow"
        width="35%"
        :before-close="editpaymentShowClose">
          <el-form style="width:95%;" :model="editData" :rules="editpaymentrules" ref="AddData" size="small" label-width="100px">
            <!-- <el-form-item label="用户账号" prop="account">
              <el-input v-model="editData.account"></el-input>
            </el-form-item>
            <el-form-item label="账号密码" prop="password">
              <el-input v-model="editData.password"></el-input>
            </el-form-item> -->
            <el-form-item label="用户名称">
              <el-input v-model="editData.username"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="alarmPhone">
              <el-tooltip effect="dark" content="多个手机号以英文字母逗号隔开" placement="top">
                <el-input v-model="editData.alarmPhone"></el-input>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="短信报警">
              <el-switch v-model="editData.msgAlarmFlag"></el-switch>
            </el-form-item>
            <el-form-item label="授权设备列表">
              <el-select v-model="editData.ids" multiple filterable placeholder="请选择用户账号" style="width:100%;">
                  <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.terminalId"
                  :value="item.id">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="editpaymentsubmitm('AddData')" style="float:right;margin:5% 0 0 2%;">确定</el-button>
              <el-button @click="editpaymentresetForm" style="float:right;margin:5% 0 0 2%;">取消</el-button>
            </el-form-item>
          </el-form>
      </el-dialog>
      <!-- 抽屉 查看设备详情功能 -->
      <el-drawer title="设备终端详情列表" :visible.sync="drawer" size="500px" @close="closedrawerno">
        <div class="detilsBox">
          <h5>账号 {{title}}</h5>
          <div class="detilconent" v-for="(v,k) in delistData" :key="k">
            <span>终端型号：{{v.terminalModel}}</span>
            <span>终端ID：{{v.terminalId}}</span>
          </div>
        </div>
      </el-drawer>
    </div>
    <!-- 内容结束 -->
  </div>
</template>
<script>
export default {
    data() {
        return {
          loading:false,
          current:1,              // 当前页数
          size:10,                // 显示条数
          total:0,                // 总条数
          delmul:[],              // 批量删除
          show:false,
          purTitle: "",           // 标题
          purSearch: "",          // 搜索        
          multipleSelection: [],  // 选中数据
          tableData: [],          // 表格数据  
          // 新建用户信息弹窗
          paymentShow:false,
          AddData:{
            equipmentType: 1,
            msgAlarmFlag:false
          },
          paymentrules: {
              account: [
                { required: true, message: '请输入用户账号', trigger: 'blur' }
              ],
              password: [
                { required: true, message: '请输入账号密码', trigger: 'blur' }
              ],
              alarmPhone: [
                { required: true, message: '请输入手机号', trigger: 'blur' },
                { pattern:/^(\d+[,])*(\d+)$/, message: '请输入正确的号码格式', trigger: 'blur'}
              ],
          },
          options: [],
          // 用户编辑
          editpaymentShow:false,
          editData:{},
          editpaymentrules: {
              // account: [
              //   { required: true, message: '请输入用户账号', trigger: 'blur' }
              // ],
              // password: [
              //   { required: true, message: '请输入账号密码', trigger: 'blur' }
              // ],
              alarmPhone: [
                { required: true, message: '请输入手机号', trigger: 'blur' },
                { pattern:/^(\d+[,])*(\d+)$/, message: '请输入正确的号码格式', trigger: 'blur'}
              ],
          },
          // 设备详情
          drawer:false,
          title:'',
          delistData:[],
        };
    },
    created() {},
    mounted(){
      this.purTitle = this.$route.meta.title;
      this.getpurcon();
    },
    methods: {
      // 重置密码
      Resetpassward(id){
        this.api.User.resetPwd({id:id})
        .then(res=>{
          if(res.data.code == 200){
              this.$message({
                type: 'success',
                message: '重置密码成功,初始密码为 123456'
              });
          }
        })
      },
      // 设备详情
      details(data){
        this.drawer = true;
        this.title = data.account;
        this.api.Terminal.list({userId:data.id})
        .then(res=>{
          if(res.data.code == 200){
            this.delistData = res.data.data;
          }
        })
      },
      // 关闭抽屉回调
      closedrawerno(){
        this.drawer = false;
      },
      // 表格数据
      getpurcon(){
        this.loading = true;
        // 传参
        var listByPageData = {
          level:1,
          applys:2,
          searchStr:this.purSearch,
          page:{
            current:this.current,
            size:this.size
          }
        }
        // 渲染表格
        this.api.User.all(listByPageData)
        .then(res=>{
          this.loading = false;
          if(res.data.code == 200){
              this.tableData = res.data.data.records;
              this.total = res.data.data.total;
          }
        })
      },
      // 搜索功能
      search() {
        this.tableData = [];
        this.getpurcon();
        this.current = 1;
      },
      // 表格选中数据
      purChange(val) {
        this.multipleSelection = val;
        this.delmul = [];
        for(let i=0;i<this.multipleSelection.length;i++){
          this.delmul.push(this.multipleSelection[i].id) 
        }
      },
      // 设备号列表
      road(){
        this.api.Terminal.list()
        .then(res=>{
          if(res.data.code == 200){
            this.options = res.data.data;
          }
        })
      },
      // 新建用户信息
      addPurs(){
        this.paymentShow = true;
        this.road();
      },
      // 新建用户信息取消
      paymentShowClose(){
        this.paymentShow = false;
        this.AddData = {
            equipmentType: 1,
            isReceiveMsg:false
        }
      },
      // 取消新建用户信息提交
      paymentresetForm() {
        this.paymentShow = false;
        this.AddData = {
            equipmentType: 1,
            isReceiveMsg:false
        }
      },
      // 新建用户信息提交
      paymentsubmitm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.api.User.add(this.AddData)
            .then(res=>{
              if(res.data.code == 200){
                this.$message({
                  type: 'success',
                  message: '保存成功!'
                });
                this.AddData = {
                  equipmentType: 1,
                  isReceiveMsg:false
                }
                this.tableData = [];
                this.getpurcon();
                this.current = 1;
                this.paymentShow = false;
              }
            })
          }
        });
      },
      // 编辑用户信息
      EditPurs(data){
        this.editpaymentShow = true;
        this.road();
        this.api.User.one({id:data.id})
        .then(res=>{
          if(res.data.code == 200){
            if(this.options){
              this.editData = res.data.data;
            }
            
          }
        })
        ;
      },
      // 编辑用户信息取消
      editpaymentShowClose(){
        this.editpaymentShow = false;
        this.editData = {}
      },
      // 取消编辑用户信息提交
      editpaymentresetForm() {
        this.editpaymentShow = false;
        this.editData = {}
      },
      // 编辑用户信息提交
      editpaymentsubmitm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.api.User.edit(this.editData)
            .then(res=>{
              if(res.data.code == 200){
                this.$message({
                  type: 'success',
                  message: '编辑成功!'
                });
                this.editData = {}
                this.tableData = [];
                this.getpurcon();
                this.current = 1;
                this.editpaymentShow = false;
              }
            })
          }
        });
      },
      // 删除功能
      openPur() {
          if(this.multipleSelection == ""){
            this.$message({
                type: 'warning',
                message: '请选择要删除的数据!'
            });
          }else{
            this.$confirm('此操作将永久删除该用户信息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
              this.api.User.del(this.delmul)
              .then(res=>{
                if(res.data.code == 200){
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
                  this.tableData = [];
                  this.getpurcon();
                  this.current = 1;
                  if(this.tableData == []){
                      this.dataAva = true;
                  }   
                }
              })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
          }
      },
    },
};
</script>
<style lang="less" scoped>
// 详情样式开始
.detilsBox{
  width: 100%;
  height: 100%;
  padding: 25px;
  box-sizing: border-box;
  h5{
    font-size: 16px;
    line-height: 58px;
    color: #333333;
  }
  .detilconent{
    width: 100%;
    span{
      display: inline-block;
      width: 50%;
      font-size: 14px;
      line-height: 24px;
      color: #333333;
    }
  }
}
// 详情样式结束
// 头部开始
.pur-top {
  width: 100%;
  height: 50px;
  padding: 9px 24px 9px 32px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  border: 1px solid rgba(102, 102, 102, 0.15);
  .pur-size {
    font-size: 16px;
    line-height: 32px;
    color: #333;
    font-weight: 600;
  }
  .pur-right {
    .el-input {
      width: 200px;
      float: right;
    }
    .el-button {
      margin-left: 16px;
      float: right;
    }
  }
}
// 头部结束
// 内容开始
.pur-nav {
  width: calc(100% - 32px);
  height: calc(100vh - 142px);
  background-color: #fff;
  margin: 8px;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  padding: 8px;
  .pur-table{
    width:100%;
    height: 94%;
  } 
  // 暂无数据样式开始
  .dataAvailable{
    width: 100%;
    height: 245px;
    position: relative;
    margin-top:185px;
    margin-bottom:279px;
    .dataAva{
      position: absolute;
      top:75%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      img{
        width: 371px;
        height: 200px;
        margin: auto;
      }
      p{
        font-size: 16px;
        line-height: 24px;
        color: #333;
      }
      .el-button{
        margin-bottom:100px;
      }
    }
  }
  // 暂无数据样式结束
  .pur-pages {
    width: 100%;
    height: 6%;
    padding: 6px 0 0 12px;
    box-sizing:border-box;
    .el-pagination {
      float: right;
    }
  }
}
// 内容结束
</style>